/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines-per-function */
import App from '../components/App'
import Header from '../components/Header'
import React, { useLayoutEffect } from 'react'
import SEO from '../atoms/SEO'
import qs from 'qs'
import styled from 'styled-components'
import { Grid } from '../atoms/Grid'
import { NewForm } from '../organisms/NewForm'
import { SendCodeModalWithContext } from '../organisms/RegistrationModals/CodeSendModal'
import { pick } from 'rambda'
import { size } from '../constants'
import { useFormSubmit } from '../atoms/useFormSubmit'
import { useLocation } from '@reach/router'
import { useSendCodeModalContext } from '../organisms/RegistrationModals/CodeSendModal/hooks/useSendCodeModalContext'
import { useSessionStorage } from '../../youtalk-storybook/src/ui'
import { useTrackConsultantStartEffect } from '../components/amplitude/trackClientServiceConsultantStart'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #e5ecf1;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`
const PageGrid = styled(Grid)`
  width: 587px;
  margin-top: 48px;
  margin-bottom: 330px;

  @media (max-width: ${size.lg}) {
    margin-bottom: 89px;
  }

  @media (max-width: ${size.md}) {
    margin-bottom: 103px;
  }

  @media (max-width: ${size.sm}) {
    margin-top: 24px;
    margin-bottom: 160px;
    width: 100%;
  }
`

const usePageLocation = () => {
  const location = useLocation()

  const searchObj = qs.parse(location?.search, { ignoreQueryPrefix: true })

  return {
    psychologistID: searchObj.id,
    state: location.state,
    promocode: searchObj.pt_promocode
  }
}

const CATALOG_FILTERS_STORAGE = 'catalog_filters'

const PageBody = () => {
  const { psychologistID, state, promocode } = usePageLocation()
  const [catalogFiltersData, setCatalogFiltersData] = useSessionStorage(
    CATALOG_FILTERS_STORAGE,
    {
      psychologistID
    }
  )

  const context = useSendCodeModalContext()

  useLayoutEffect(() => {
    if (state?.filters) {
      setCatalogFiltersData({
        ...catalogFiltersData,
        ...state.filters
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useTrackConsultantStartEffect()

  const submit = useFormSubmit({
    catalogFiltersData,
    attempt: Number(context.formData.attempt ?? 0)
  })

  return (
    <NewForm
      id={psychologistID}
      initialValues={{
        ...pick(['age', 'name', 'aboutHelp', 'sourceId'], catalogFiltersData),
        promo: promocode
      }}
      isModal={false}
      onSubmit={submit}
    />
  )
}

const ApplicationForm = () => (
  <App>
    <SEO title="Запись к психологу онлайн | YouTalk" />
    <Page>
      <Header />
      <PageGrid>
        <SendCodeModalWithContext>
          <PageBody />
        </SendCodeModalWithContext>
      </PageGrid>
    </Page>
  </App>
)

export default ApplicationForm
